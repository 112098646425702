import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpBackend, HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {StatisticDTO} from '../../model/dto/statistic.dto';
import {ReportingDto} from '../../model/dto/reporting.dto';
import {ReportingBarCard} from '../../model/reporting/bar/reporting-bar-card.model';
import {getMobileToken} from 'src/app/utils/utils.static';
import {AbstractWebservice} from "./abstract.webservice";

@Injectable({
  providedIn: 'root'
})
export class StatisticsWebservice extends AbstractWebservice {
  entity = `statistics`;

  constructor(protected readonly httpClient: HttpClient,
              protected  readonly httpBackend: HttpBackend) {
    super(httpClient, httpBackend);
  }

  getPresenceStatistics(listOfIdPlace: number[], date?: Date): Observable<StatisticDTO> {
    if (date) {
      return this.post<StatisticDTO>(
        `${this.root}/presence/${date.getMonth()}/${date.getFullYear()}`,
        listOfIdPlace
      );
    } else {
      return this.post<StatisticDTO>(`${this.root}/presence`, listOfIdPlace);
    }
  }

  getPresenceTrancheStatistics(listOfIdPlace: number[], date?: Date): Observable<StatisticDTO> {
    return this.post<StatisticDTO>(
      `${this.root}/presence/tranche/${date.getMonth()}/${date.getFullYear()}`,
      listOfIdPlace
    );
  }

  getProgramRegistrationStatistics(listOfIdPlace: number[], date: Date): Observable<StatisticDTO> {
    return this.post<StatisticDTO>(
      `${this.root}/programmeinscription/${date.getMonth()}/${date.getFullYear()}`,
      listOfIdPlace
    );
  }

  /** REPORTING**/
  /** Suivi **/
  getReportingRegistrationStatistics(reportingDto: ReportingDto): Observable<StatisticDTO> {
    return this.post<StatisticDTO>(
      `${this.root}/reporting/inscription/activite`,
      reportingDto
    );
  }

  getReportingRegistrationGenderStatistics(reportingDto: ReportingDto): Observable<StatisticDTO> {
    return this.post<StatisticDTO>(`${this.root}/reporting/inscription/genre`, reportingDto);
  }

  getReportingNbInscritByEventByTranche(reportingDto: ReportingDto) {
    return this.post<ReportingBarCard>(`${this.root}/reporting/sessionByEvent/`, reportingDto);
  }

  getReportingPresenceByEventByMonth(reportingDto: ReportingDto) {
    return this.post<ReportingBarCard>(
      `${this.root}/reporting/presenceByEvent/`,
      reportingDto
    );
  }

  /** Reinscription **/
  getReportingNewOldInscritByEvent(reportingDto: ReportingDto) {
    return this.post<ReportingBarCard>(
      `${this.root}/reporting/newOldInscritByEvent/`,
      reportingDto
    );
  }

  getReportingNewOldInscritBySex(reportingDto: ReportingDto) {
    return this.post<ReportingBarCard>(
      `${this.root}/reporting/newOldInscritBySex/`,
      reportingDto
    );
  }

  /** Autre **/
  getReportingNumberEvents(reportingDto: ReportingDto) {
    return this.post<StatisticDTO>(`${this.root}/reporting/numberEvents/`, reportingDto);
  }

  getReportingSexByEvent(reportingDto: ReportingDto) {
    return this.post<ReportingBarCard>(`${this.root}/reporting/sexByEvent/`, reportingDto);
  }
}
